import React, { useEffect, useRef, useState, useImperativeHandle } from "react";
import { Decimal } from 'decimal.js';
import * as echarts from 'echarts';
const PieChart = React.forwardRef((props, ref) => {
  useImperativeHandle(
    ref,
    () => ({ dataChart, fetchData})
  );
    const [dataChart, setDataChart] = useState({
        initData:[
            { value: 0, name: '1<UTXOs≤10', min:-1,max:10 },
            { value: 0, name: '10<UTXOs≤20', min:10,max:20 },
            { value: 0, name: '20<UTXOs≤30', min:20,max:30 },
            { value: 0, name: '30<UTXOs≤40', min:30,max:40 },
            { value: 0, name: '40<UTXOs≤50', min:40,max:50 },
            { value: 0, name: '50<UTXOs≤100', min:50,max:100 },
            { value: 0, name: '100<UTXOs≤1000', min:100,max:1000 },
            { value: 0, name: '1000<UTXOs', min:1000,max:100000000 },
    ],option:[],total:0})
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = echarts.init(chartRef.current);
        // chart.showLoading()
        const option = {
            tooltip: {
                trigger: "item",
                confine: true,
                borderColor: "#404040",
                alwaysShowContent: false,
                backgroundColor:  "#404040",
                formatter: "{b}<br/>{d}%",
                textStyle: {
                    align: "left",
                    fontSize: 14,
                    color: "#ffffff",
                },
                axisPointer: {
                    type: "shadow",
                },
            },
            legend: {
                orient: 'vertical',
                icon: 'circle',
                verticalAlign:'top',
                selectedMode:false,
                itemGap: 15,
                textStyle: {
                    fontSize: 12,
                },
                itemWidth:11,
                itemHeight:11,
                right: '0'
            },
            itemStyle: {
                borderColor: '#000',
                borderWidth: 1
            },
            series: [
                {
                    type: 'pie',
                    radius: ['43%', '60%'],
                    label: {
                        show: false,
                        position: "inside"
                    },
                    color: ["#6686f6", "#c8b9ff", "#f28e4a", "#06df6c","#e6bb4b","#f382f6","#fedc00","#e2a4a1","#1f9cf0","#98d5da"],
                    center:window.outerWidth>980?['23%', '31%']:['26%','27%'],
                    data: [],
                },
            ],
        };
        chart.setOption(option);
        window.onresize = function() {
            chart.resize();
        };
        return () => {
            chart.dispose();
        };
    }, []);
    useEffect(() => {
        try{
            fetchData()
        } catch (e) {
            console.error(e)
        }
    }, [])
    async function fetchData() {
        const chart = echarts.getInstanceByDom(chartRef.current);
        const url ='https://api.houndcoin.org/getUtxoHis'
        // const url ='api/getUtxoHis'
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
        .then((data) => {
            // console.log(data)
            // setData(data)
            let dataList = []
            let total = 0
            for(let i =0;i<dataChart.initData.length;i++) {
                let val = 0
                const item = dataChart.initData[i]
                const list = data.filter(dataItem => item.min<=dataItem.key && item.max>dataItem.key )
                // console.log(list)
                for (var jj = 0; jj < list.length; jj++) {
                    const aa = new Decimal(val)
                    const bb = new Decimal(list[jj].doc_count)
                    val = Decimal(aa).add(Decimal(bb))
                }
                dataList.push({
                    name: item.name,
                    value: Number(val)
                })
            }
            console.error(dataList)
            data.map(item => {
                const total1 = new Decimal(total)
                const total2 = new Decimal(item.doc_count)
                total = Decimal(total1).add(Decimal(total2))
            })
            console.error(Number(total))

            const option = {
                tooltip: {
                    trigger: "item",
                    confine: true,
                    borderColor: "#404040",
                    alwaysShowContent: false,
                    backgroundColor:  "#404040",
                    formatter: "{d}% of Addresses<br/>{b}",
                    textStyle: {
                        align: "left",
                        fontSize: 14,
                        color: "#ffffff",
                    },
                    axisPointer: {
                        type: "shadow",
                    },
                },
                legend: {
                    orient: 'vertical',
                    icon: 'circle',
                    verticalAlign:'top',
                    selectedMode:false,
                    itemGap: 15,
                    textStyle: {
                        fontSize: 12,
                    },
                    itemWidth: window.outerWidth>500? 11 : 7,
                    itemHeight: window.outerWidth>500? 11 : 7,
                    right: '0'
                },
                series: [
                    {
                        type: 'pie',
                        radius: window.outerWidth>980?['40%', '60%']:['34%','50%'],
                        label: {
                            show: false,
                            position: "inside"
                        },
                        color: ["#6686f6", "#c8b9ff", "#f28e4a", "#06df6c","#e6bb4b","#f382f6","#fedc00","#e2a4a1","#1f9cf0","#98d5da"],
                        center:['23%', '31%'],
                        data: dataList,
                    },
                ],
            };
            chart.setOption(option);
            // chart.hideLoading()
            setDataChart({
                initData: dataChart.initData,
                total: Number(total),
                option: dataList
            })

        })
        .catch((error) => {
            console.error(error)
        })
    }

    return <div className="pie-container"><div ref={chartRef} className="chart"></div><span className="desc">Address Distribution<br/>(UTXOs>1)</span></div>;
});

export default PieChart;
